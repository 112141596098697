import React, { useEffect, useState } from "react";

const CraftErrors = ({ errors: initErrors, duration = 3000 }) => {
  const [errorList, setErrorList] = useState(initErrors);

  useEffect(() => {
    setErrorList(initErrors);
  }, [initErrors]);

  useEffect(() => {
    if (errorList.length > 0) {
      const timer = setTimeout(() => {
        setErrorList([]);
      }, duration);

      return () => clearTimeout(timer); // Clear the timer on unmount
    }
  }, [errorList, duration]);

  return (
    <>
      {errorList.map((error, i) => (
        <div
          key={i}
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">{error}</span>
        </div>
      ))}
    </>
  );
};

export default CraftErrors;
